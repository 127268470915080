import PropTypes from 'prop-types';
import classNames from 'classnames';
import { resolveChildren } from '../../lib/Utils';

import './Title.scss';

export const TITLE_MARGIN = {
  NORMAL: 'normal',
  THIN: 'thin',
  NONE: 'none'
};

export const TITLE_WEIGHT = {
  LIGHT: 'light',
  NORMAL: 'normal',
  BOLD: 'bold'
};

const Note = ({ children, style }) => <span style={style} className={'system-title__left-note'}>{children}</span>;
const Text = ({ children, style }) => <span style={style}>{children}</span>;

const Left = ({ children, style }) => <div style={style} className={'system-title__left'}>{resolveChildren(children, { Text, Note })}</div>;
const Right = ({ children }) => <div className={'system-title__right'}>{children}</div>;

const Title = (props) => {
  const {
    style,
    size,
    children,
    margin,
    className,
    weight,
    border,
    onClick
  } = props;

  const cn = classNames(
    `system-title system-title--${size}`,
    `system-title--weight-${weight}`,
    `system-title--margin-${margin}`,
    {
      'system-title--bordered': border,
      [className]: className
    }
  );
  return (
    <div
      className={cn}
      style={style}
      onClick={onClick}
    >
      {resolveChildren(
        children,
        {
          Left,
          Right
        },
        () => <Left>{children}</Left>
      )}
    </div>
  );
};

Title.propTypes = {
  size: PropTypes.number,

  margin: PropTypes.string,
  weight: PropTypes.string,

  border: PropTypes.bool
};

Title.defaultProps = {
  size: 1,

  margin: TITLE_MARGIN.NORMAL,
  weight: TITLE_WEIGHT.NORMAL,

  border: false
};

export default Title;
