import { SYSTEM_COLOR } from '_Lib/Enum';
import { SVGExclamationSolid } from '_Icons/main';
import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';

export const ErrorMessage = ({ children, className = '', ...restProps }) => (
  <div
    data-cy={'error-message'}
    {...restProps}
    style={{
      border: `1px solid ${SYSTEM_COLOR.CHART.RED}`,
      borderRadius: 4,
      padding: '4px 8px',
      display: 'flex'
    }}
    className={`error-message-message ${className}`}
  >
    <span className={'error-message__icon'} >
      <SVGExclamationSolid
        size={14}
        color={SYSTEM_COLOR.CHART.RED}
        style={{ marginRight: 8 }}
      />
    </span>
    <P textSize={PARAGRAPH_SIZES.P3}>
      {children}
    </P>
  </div>
);
