import QRCode from 'react-qr-code';
import { Column, ColumnItem } from '_Elements/layout';
import { Link } from '_Elements/text';

const QRCodeCard = (props) => {
  const {
    value,
    size,
    title,
    width,
    downloadable,
  } = props;

  const onClick = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Column spacing={8} style={{ width, alignItems: 'center' }}>
      <ColumnItem>{title}</ColumnItem>
      <ColumnItem>
        <QRCode
          id={'QRCode'}
          value={value}
          size={size}
          style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
          viewBox={`0 0 ${size} ${size}`}
        />
      </ColumnItem>
      {downloadable && (
        <ColumnItem>
          <Link onClick={onClick} >Download QR code</Link>
        </ColumnItem>
      )}
    </Column>
  );
};

QRCodeCard.defaultProps = {
  title: 'QR code',
  downloadable: true,
  width: 150,
  size: 256,
};

export default QRCodeCard;
