import { useMemo } from 'react';
import moment from 'moment-timezone';
import { ToolTipWrapper, TOOLTIP_SIZE } from '_Elements/tool-tip';
import { isString } from '_Lib/Utils';
import classNames from 'classnames';
import './LocalDate.scss';

const LocalDate = (props) => {
  const {
    date,
    style,
    timezone,
    format,
    showTimezone
  } = props;

  const {
    diffTz,
    display,
    localDisplay
  } = useMemo(
    () => {
      let d = date;
      if (isString(d)) d = moment.utc(d).tz(timezone);
      if (!moment.isMoment(d) || !d.isValid()) return ({ display: '' });
      let diffTz = d.tz() != moment.tz.guess();

      let localDisplay = d.clone()
        .tz(moment.tz.guess())
        .format(format);
      return ({
        diffTz,
        display: d.format(format) + (showTimezone && diffTz? ` in ${d.tz()}`: ''),
        localDisplay: `${localDisplay} in ${moment.tz.guess()}`
      });
    },
    [date, format, timezone, showTimezone]
  );

  return (
    <ToolTipWrapper
      content={localDisplay}
      size={TOOLTIP_SIZE.SMALL}
      disabled={!diffTz}
    >
      <b
        className={
          classNames(
            'display-text-local-date',
            {
              ['display-text-local-date--diff-timezone']: diffTz
            }
          )
        }
        style={style}
      >
        {display}
      </b>
    </ToolTipWrapper>
  );
};

LocalDate.defaultProps = {
  format: 'Do MMMM YYYY HH:mm',
  showTimezone: true,
  timezone: moment.tz.guess()
};

export default LocalDate;
