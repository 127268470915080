import { ToolTip } from '_Elements/tool-tip';
import { SYSTEM_COLOR } from '_Lib/Enum';
import CountLabel from '_Elements/label/CountLabel';
import { Row, RowItem } from '_Elements/layout';

const Legend = ({ items, columnHeight = 2 }) => {
  const columns = items.reduce(
    (rollingColumns, next, index) => {
      let currentColumn = (index % columnHeight === 0)
        ? []
        : rollingColumns.pop();
      currentColumn = [...currentColumn, next];
      return [...rollingColumns, currentColumn];
    },
    []
  );

  return (
    <Row spacing={40}>
      {columns.map(
        (column, i) => (
          <RowItem column key={i} >
            {column.map(
              ({ label, value, color, tooltip }) => (
                <RowItem key={label} style={{ display: 'flex' }}>
                  <CountLabel color={color} textColor={SYSTEM_COLOR.TEXT_BLACK} count={value}>{label}</CountLabel>
                  {tooltip && (
                    <ToolTip
                      style={{ marginLeft: 8 }}
                      content={tooltip}
                    />
                  )}
                </RowItem>
              )
            )}
          </RowItem>
        )
      )}
    </Row>
  );
};

export default Legend;
