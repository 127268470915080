
import { useMemo } from 'react';
import { useTextOverflow } from '_Hooks/';
import './SurveyNameOverflowLabel.scss';

const SurveyNameOverflowLabel = ({ text, fixed, ...rest }) => {
  const [bind, { lines, clip }] = useTextOverflow(text, { fixed, maxLines: 1 });
  const label = useMemo(
    () => {
      if (!clip) return text;
      if (!lines[0]) return text;
      const { _obj: wordsWithWidth, spaceWidth, boxWidth } = lines[0];
      const dotsWidths = (spaceWidth * 4);

      const fitsInBoxWidth = words => (words.pluck('width').sum(x => x + spaceWidth) + dotsWidths) <= boxWidth;

      let wordSet = [...wordsWithWidth],
        leftWords = [],
        rightWords = [],
        roomInLeft = true,
        roomInRight = true;

      const canAdd = word => fitsInBoxWidth([...leftWords, word, ...rightWords]);

      while (roomInLeft || roomInRight) {
        roomInLeft = canAdd(wordSet[0]);
        if (roomInLeft) leftWords = [...leftWords, ...wordSet.splice(0, 1)];

        const last = wordSet.length -1;
        roomInRight = canAdd(wordSet[last]);
        if (roomInRight) rightWords = [...wordSet.splice(last, 1), ...rightWords];
      }

      const left = leftWords
        .pluck('word')
        .join(' ');
      const right = rightWords
        .pluck('word')
        .join(' ');
      return `${left} ... ${right}`;
    },
    [lines, clip, text]
  );

  return (
    <div
      {...bind}
      {...rest}
      className={'survey-name-text-label'}
      data-cy={'survey-name-text-label'}
    >
      {label}
    </div>
  );
};

export default SurveyNameOverflowLabel;
