import PropTypes from 'prop-types';
import { resolveChildren } from '../../lib/Utils';

import './Paragraph.scss';

export const PARAGRAPH_THEMES = {
  NORMAL: 'normal',
  BOLD: 'bold'
};

export const PARAGRAPH_FONT_SIZE = {
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large'
};

const Text = ({ children, style }) => <span style={style} className={'system-paragraph__text'}>{children}</span>;
const Note = ({ children, style }) => <span style={style} className={'system-paragraph__note'}>{children}</span>;

const Paragraph = ({ children, style, theme, size, className }) => (
  <div
    className={`system-paragraph system-paragraph--theme-${theme} system-paragraph--size-${size} ${className || ''}`}
    style={style}
  >
    {resolveChildren(
      children,
      { Text, Note },
      () => <Text>{children}</Text>
    )}
  </div>
);

Paragraph.propTypes = {
  theme: PropTypes.string,
  size: PropTypes.string
};

Paragraph.defaultProps = {
  theme: PARAGRAPH_THEMES.NORMAL,
  size: PARAGRAPH_FONT_SIZE.NORMAL
};

export default Paragraph;
