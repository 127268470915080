import { P, PARAGRAPH_SIZES } from '_Elements/text/v2';
import { SVGParticipantCount } from '_Icons';
import { SYSTEM_COLOR } from '_Lib/Enum';
import { SVGLoadingSpinner } from '_Icons/loading';

export const DisplayFilteredCounts = ({
  count,
  total_respondents,
  loading,
  showIcon
}) => {
  let text = `${total_respondents} participants`;
  if (count !== undefined) text = `${count} of ${text}`;
  return (
    <P
      textSize={PARAGRAPH_SIZES.P3}
      style={{ display: 'flex', alignItems: 'center' }}
      data-loading={!!loading}
    >
      {loading && <SVGLoadingSpinner size={10} color={SYSTEM_COLOR.PRIMARY} style={{ marginRight: 8 }} />}
      {showIcon && <SVGParticipantCount size={16} color={SYSTEM_COLOR.TEXT_BLACK} style={{ marginRight: 8 }} />}
      <span>{text}</span>
    </P>
  );
};
