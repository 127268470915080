import './FormDetail.scss';

export const FormDetail = ({ children, label, style }) => (
  <div
    style={style}
    className={'form-detail-bounding-box'}
    data-component={'form-detail'}
  >
    <div
      className={'form-detail-bounding-box__label'}
    >
      {label}
    </div>
    <div className={'form-detail-bounding-box__box'} >
      {children}
    </div>
  </div>
);
