import { forwardRef } from 'react';
import './FormLabel.scss';

export const FormLabel = forwardRef(
  function FormLabel (
    {
      style,
      defaultMargin,
      children,
      ...rest
    },
    ref
  ) {
    return (
      <label
        ref={ref}
        className={'modular-form-label'}
        style={{
          ...defaultMargin && ({ marginBottom: 4 }),
          ...style
        }}
        {...rest}
      >
        {children}
      </label>
    );
  }
);
