import { useCallback } from 'react';
import Paragraph, { PARAGRAPH_THEMES, PARAGRAPH_FONT_SIZE } from '../Paragraph';
import { SVGHelp } from '_Icons';
import { Link } from '../Link';

export const MoreInfo = (props) => {
  const {
    href,
    theme,
    size,
    align,
    trackTitle,
    children
  } = props;

  const onClick = useCallback(
    () => {
      metrics.track('Help Link Followed', { link: href, name: trackTitle });
    },
    [href, trackTitle]
  );

  return (
    <Paragraph
      theme={theme}
      size={size}
    >
      {({ Text }) => (
        <>
          {align == 'left' && (<Text style={{ marginRight: 8 }}><SVGHelp size={16} style={{ width: '1em', height: '1em' }} /></Text>)}
          <Text><Link href={href} onClick={onClick} >{children}</Link></Text>
          {align != 'left' && (<Text style={{ marginLeft: 8 }}><SVGHelp size={16} style={{ width: '1em', height: '1em' }} /></Text>)}
        </>
      )}
    </Paragraph>
  );
};

MoreInfo.defaultProps = {
  theme: PARAGRAPH_THEMES.BOLD,
  size: PARAGRAPH_FONT_SIZE.NORMAL,
  align: 'right'
};
